import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section } from "../components/atoms/Section"
import { PageProp } from "."
import { navigateToPrevOrDefault } from "../utils"
import { Button } from "../components/atoms/Button"

const NotFoundPage: React.FC<PageProp> = ({ location }) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <Section>
      <h1>NOT FOUND</h1>
      <p>You've hit a page that does not exist. would you like to go back?</p>
      <nav className="pt-20 pb-16 pl-4 sm:pt-32 sm:pl-0">
        <Button text="BACK" onClick={() => navigateToPrevOrDefault("/")} />
      </nav>
    </Section>
  </Layout>
)

export default NotFoundPage
